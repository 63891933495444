<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col cols="4">
          <div class="mb-1">
            {{ $t("Category:")
              }}<!--  <strong>{{ selected }}</strong> -->
            <b-button variant="primary" class="ml-1" pill style="padding: 2px" v-b-modal.newcatmodal><feather-icon
                icon="PlusIcon"> </feather-icon></b-button>
          </div>
          <b-form-select v-model="selected" :options="options" />
        </b-col>
        <b-col cols="8">
          <div class="mb-1">
            {{ $t("Question") }}
          </div>
          <b-form-input v-model="question" :placeholder="$t('Write Your Question')" />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <div class="mb-1 d-flex flex-row">
            <span>
              {{ $t("Answer") }}
            </span>
            <b-form-checkbox class="ml-2" v-model="forParents">
              {{ $t("For Parents") }}
            </b-form-checkbox>
            <b-form-checkbox class="ml-2" v-model="forProfessionals">
              {{ $t("For Professionals") }}
            </b-form-checkbox>
          </div>
          <b-form-textarea v-model="answer" :placeholder="$t('Your Answer')" />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="sendQandA">
            {{ $t("Send Your Question And Answer") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-modal id="newcatmodal" :title="$t('New Category')" :ok-title="$t('Add')" :cancel-title="$t('Cancel')"
      @ok="newQACategory" centered>
      <b-form-input v-model="newCategory" :placeholder="$t('New Category Name')" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BButton,
  BModal,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "@axios";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BButton,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  data() {
    return {
      question: "",
      answer: "",
      selected: null,
      options: [
        { value: null, text: "Kategori Seçiniz" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Simple Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      newCategory: "",
      forParents: false,
      forProfessionals: false,
    };
  },
  /* add prop */
  props: {},
  async created() {
    this.fetchQAs();
  },
  methods: {
    async sendQandA() {
      console.log(this.$router.currentRoute.params.id);
      // console.log(this.$router.params.id);
      if (
        this.$router.currentRoute.query !== undefined &&
        this.$router.currentRoute.query !== null &&
        this.$router.currentRoute.query !== "" &&
        parseInt(this.$router.currentRoute.params.id) !== 1
      ) {
        await axios.patch("/qa/qa/" + this.$router.currentRoute.params.id, {
          question: this.question,
          ans: this.answer,
          categoryName: this.selected,
          forProfessionals: this.forProfessionals,
          forParents: this.forParents,
        });
        this.$router.go(-1);
        return;
      }
      await axios.post("/qa/qa", {
        question: this.question,
        ans: this.answer,
        categoryName: this.selected,
        forProfessionals: this.forProfessionals,
        forParents: this.forParents,
      });
      this.$router.go(-1);
    },
    async fetchQAs() {
      const resCat = await axios.get("/qa");
      // console.log(resCat.data.faqData);
      const pseudoOptions = [];
      for (let i = 0; i < Object.keys(resCat.data.faqData).length; i++) {
        pseudoOptions.push({
          text: resCat.data.faqData[Object.keys(resCat.data.faqData)[i]].title,
          value: Object.keys(resCat.data.faqData)[i],
        });
      }
      this.options = [...pseudoOptions];
      // console.log(this.options);
      if (
        this.$router.currentRoute.query !== undefined &&
        this.$router.currentRoute.query !== null &&
        this.$router.currentRoute.query !== "" &&
        parseInt(this.$router.currentRoute.params.id) !== 1
      ) {
        this.question = this.$router.currentRoute.query.question;
        this.answer = this.$router.currentRoute.query.ans;
        this.selected = this.options?.filter(
          (x) => x.text == this.$router.currentRoute.query.category
        )[0].value;
        this.forParents = this.$router.currentRoute.query.forParents;
        this.forProfessionals = this.$router.currentRoute.query.forProfessionals;
      }
    },
    async newQACategory() {
      console.log(this.newCategory);
      if (this.newCategory == "") return;
      await axios.post("/qa", {
        title: this.newCategory,
      });
      this.newCategory = "";
      this.fetchQAs();
    },
  },
};
</script>
